import React, { useEffect, useState } from "react";
import {
    Button,
    Box,
    HStack,
    Spacer,
    Text
} from '@chakra-ui/react';
import { BsShop } from 'react-icons/bs';
import {
    callServiceAdvertise
} from './util';

import DonatePage from "./DonatePage";
import CopyButton from "./CopyButton";
import { lang } from "./config"
import { useTranslation } from "react-i18next";

const BrandAdvertise = () => {
    const { t } = useTranslation();
    const [count, setCount] = useState(0);
    const brandList = [
        {
            description: "แต่งเค้กให้ อร่อย สวย สนุก",
            name: "KP-Mama",
            //link: "https://shopee.co.th/kp_mama_shop",
            link: "https://vt.tiktok.com/ZMhSncnXG/?page=TikTokShop",
            icon: <BsShop />
        }
        /* {
            description: "ธรรมะพักใจ พัฒนาปัญญา",
            name: "Dhammalife",
            link: "https://www.dhammalife.net",
            icon: <BiBook />
        } */
    ];

    const openNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    useEffect(() => {
        setTimeout(() => {
            setCount(count + 1);
        }, 8884);
    }, [count]);

    return (
        <Box borderWidth='0px' borderRadius='3xl'
               paddingLeft='14px' paddingRight='8px' 
               paddingTop='8px' paddingBottom='8px'>
                {lang === "th" && (<HStack w="310px">
                  <Text fontSize={13} textAlign="left">
                    สนับสนุนโดย <br />
                   
                    {brandList[count % brandList.length]['description']}
                  </Text>
                  <Spacer />
                  <Button
                        leftIcon={brandList[count % brandList.length]['icon']}
                        onClick={() => {
                          openNewTab(brandList[count % brandList.length]['link'])
                          callServiceAdvertise(brandList[count % brandList.length]['name']);
                        }}
                        colorScheme="green"
                        borderRadius="3xl"
                        size="sm"
                    >{brandList[count % brandList.length]['name']}</Button>
                </HStack>)}
                {lang === "close" && (
                <HStack w="310px" mt={2}>
                  <Text fontSize={12} textAlign="left">
                    {t('supportUs')} <br />mrcount@walletofsatoshi.com
                  </Text>
                  <Spacer />
                  <CopyButton textToCopy="mrcount@walletofsatoshi.com" />
                </HStack>
                )}
        </Box>
    )
}

export default BrandAdvertise;
import React, { useState, useEffect } from 'react';
import { Flex, Tabs, Tab, TabList, VStack, Text } from '@chakra-ui/react';
import { GiTwoCoins } from "react-icons/gi";
import { FaRegCircle, FaCircle, FaGlassWhiskey } from "react-icons/fa";
import { GiBottleCap } from "react-icons/gi";
import { FaRegSquare } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

import { PRODUCT_ID_LIST } from '../constants';

function ProductTabBar() {
    const { t } = useTranslation();
    const productList = PRODUCT_ID_LIST;
    const selectIndex = productList.indexOf(localStorage.getItem('type'))
    const [tabIndex, setTabIndex] = useState(selectIndex !== -1 ? selectIndex : 0);
    const scrollBarStyle = {
        '&::-webkit-scrollbar': {
        display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
    };

    const handleTabsChange = index => {
        setTabIndex(index);
        localStorage.setItem('type', productList[index]);
         window.location.reload();
        // You can also handle other actions based on the selected tab here
        console.log(`Selected tab index: ${index}`);
      };
    
    useEffect(() => {
        productList.indexOf(localStorage)
    }, []);

    return (
        <Flex align="center" sx={scrollBarStyle} overflowX="auto" mb={0}> 
        <Tabs variant="unstyled" index={tabIndex} onChange={handleTabsChange}>
            <TabList whiteSpace="nowrap">
            <Tab _selected={{ color: 'yellow.500', borderBottom: '2px solid blue.500' }}>
                <VStack spacing={1}>
                <GiTwoCoins size={24}/>
                <Text fontSize="md">{t('coin')}</Text>
                </VStack>
            </Tab>
            <Tab _selected={{ color: 'yellow.500', borderBottom: '2px solid blue.500' }}>
                <VStack spacing={1}>
                <FaRegCircle size={24} />
                <Text fontSize="md">{t('pipe')}</Text>
                </VStack>
            </Tab>
            <Tab _selected={{ color: 'yellow.500', borderBottom: '2px solid blue.500' }}>
                <VStack spacing={1}>
                <FaCircle size={24} />
                <Text fontSize="md">{t('round-bar')}</Text>
                </VStack>
            </Tab>
            <Tab _selected={{ color: 'yellow.500', borderBottom: '2px solid blue.500' }}>
                <VStack spacing={1}>
                <GiBottleCap size={24} />
                <Text fontSize="md">{t('cap')}</Text>
                </VStack>
            </Tab>
            <Tab _selected={{ color: 'yellow.500', borderBottom: '2px solid blue.500' }}>
                <VStack spacing={1}>
                <FaRegSquare size={24} />
                <Text fontSize="md">{t('square-pipe')}</Text>
                </VStack>
            </Tab>
            <Tab _selected={{ color: 'yellow.500', borderBottom: '2px solid blue.500' }}>
                <VStack spacing={1}>
                <FaGlassWhiskey size={24} />
                <Text fontSize="md">{t('glass')}</Text>
                </VStack>
            </Tab>
            </TabList>
        </Tabs>
        </Flex>
    );
}

export default ProductTabBar;
